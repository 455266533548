import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function SitemarkIcon() {
  return (
    <SvgIcon sx={{ height: 21, width: 16, mr: 2 }}>
      <svg
        width="671"
        height="852"
        viewBox="0 0 671 852"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M444.964 2.4387C400.235 9.29122 362.626 26.3191 331.723 53.7091C326.395 58.4314 249.724 134.486 161.341 222.72L0.644531 383.145L78.1405 460.658L155.636 538.171L392.508 301.258L629.38 64.3443L624.62 60.1148C615.423 51.9428 599.538 39.8222 590.755 34.2742C564.838 17.9051 531.845 6.25231 499.11 1.90488C488.227 0.460407 455.792 0.780297 444.964 2.4387ZM278.294 551.437L41.6662 788.121L46.4313 792.703C69.7735 815.145 104.227 834.451 138.113 844.076C206.878 863.611 286.02 846.071 338.359 799.699C343.673 794.99 420.558 718.72 509.215 630.21L670.408 469.281L593.168 392.018C550.685 349.522 515.7 314.754 515.424 314.754C515.147 314.754 408.439 421.261 278.294 551.437Z"
          fill="#1E2747"
        />
      </svg>
    </SvgIcon>
  );
}
